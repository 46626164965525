import React, { useState, useEffect, useCallback, useRef, useLayoutEffect, useMemo } from 'react';
import CriiptoAuth from '@criipto/auth-js';
import jwt_decode from "jwt-decode";

import usePromise from './usePromise';
import Footer from './components/Footer';
import Header from './components/Header';
import Content, { ContentTop } from './components/Content';

import styles from './App.module.css';
import { useCriiptoVerify, QRCode } from '@criipto/verify-react';

interface CriiptoIdP {
  render: (element: HTMLElement) => void
}

declare global {
  interface Window {
    CriiptoIdP?: CriiptoIdP
  }
}

function CriiptoIdPWrapper() {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!wrapperRef.current) return;
    window.CriiptoIdP?.render(wrapperRef.current);
  }, []);

  return <div ref={wrapperRef} className={styles.verifyBox} />
}

function App() {
  const {result, error, isLoading} = useCriiptoVerify();
  const claims = useMemo(() => result && "id_token" in result ? jwt_decode<any>(result.id_token) : null, [result]);
  const redirectError = error;

  return (
    <React.Fragment>
      <Header />
      <Content>
        {window.CriiptoIdP ? (
          <React.Fragment>
            <ContentTop>
              <h1 style={{textAlign: 'center'}}>Login to manage your insurances</h1>
            </ContentTop>
            <div className={styles.boxes}>
              <CriiptoIdPWrapper />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ContentTop>
              <h1>{claims ? `Hi ${claims.name || '{name unavailable}'}` : 'Your order'}</h1>
              {claims ? (
                <p>
                  You can report injuries around the clock and follow the status of your injury.
                  If you have started a review, you will also be able to find the draft here
                </p>
              ) : (
                <p>
                  Check your order and proceed by scanning the QR code below with the camera on your smart phone.
                  You will be guided to confirm the order by logging in with your e-ID (MitID, NemID, BankID, etc.)
                </p>
              )}
            </ContentTop>
            <div className={styles.boxes}>
              <div className={styles.box} style={{flex: 1}}>
                <h2 style={{marginBottom: '48px'}}>Insurances</h2>
                <div className={styles.insuranceItem}>
                  <h4>House insurance</h4>
                  <h3>Beach alley 123, Cape Town</h3>
                  <p>
                    Yearly: DKK 12.000, Covers: Lorem ipsum dolores <a href="#" className={styles.link}>Read more...</a>
                  </p>
                </div>
                <div className={styles.insuranceItem}>
                  <h4>Car insurance</h4>
                  <h3>Mercedes SL, AA 123456</h3>
                  <p>
                    Yearly: DKK 12.000, Covers: Lorem ipsum dolores <a href="#" className={styles.link}>Read more...</a>
                  </p>
                </div>
              </div>
              <div className={`${styles.box} ${styles.qrBox}`}>
                {claims ? (
                  <React.Fragment>
                    {Object.keys(claims).filter(key => ['ssn', 'cprNumberIdentifier', 'uuid', 'identityscheme', 'authenticationtype', 'sub', 'name', 'country'].includes(key)).map(key => (
                      <div className={styles.claim}>
                        <label>{key}</label>
                        <span>{claims[key]}</span>
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <QRCode>
                    {({qrElement, isAcknowledged, isEnabled, isCancelled, retry, error, redirect}) => (
                      <React.Fragment>
                      <div className={styles.isDesktop}>
                        <React.Fragment>
                          {isEnabled == false ? (
                            <React.Fragment>
                              <h3>Confirm your order</h3>
                              <h2>An error occurred</h2>
                              <p>
                                QR does are not enabled for this application. Please go to <a href="https://dashboard.criipto.com" target="_blank" className={styles.link}>your criipto dashboard</a> to activate QR codes.
                              </p>
                            </React.Fragment>
                          ) : error ? (
                            <React.Fragment>
                              <h3>Confirm your order</h3>
                              <h2>An error occurred</h2>
                              <p>
                                {error.message ?? error}<br /> <a href="#" onClick={retry} className={styles.link}>Try again.</a><br />
                              </p>
                            </React.Fragment>
                          ) : isCancelled ? (
                            <React.Fragment>
                              <h3>Confirm your order</h3>
                              <h2>Login cancelled</h2>
                              <p>
                                <a href="#" onClick={retry}>Try again.</a><br />
                              </p>
                            </React.Fragment>
                          ) : isAcknowledged ? (
                            <React.Fragment>
                              <h3>Confirm your order</h3>
                              <h2>Pending</h2>
                              <p>Complete the login process on your phone</p>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <h3>Confirm your order</h3>
                              <h2>Scan the code</h2>
                              <p>Use the camera app on your phone.</p>
                              {qrElement}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      </div>
                      <div className={styles.isMobile}>
                        <h3>Confirm your order</h3>
                        {isLoading ? (
                           <React.Fragment>
                            <h2>Pending</h2>
                            <p>Completing the login process</p>
                          </React.Fragment>
                        ) : (redirectError || error) ? (
                          <React.Fragment>
                            <h2>An error occurred</h2>
                            <p>
                              {(redirectError || error)!.message}<br /> <a href="#" onClick={redirect} className={styles.link}>Try again.</a><br />
                            </p>
                          </React.Fragment>
                        ) : (
                          <p><button className={styles.button} onClick={redirect}>Login to confirm order</button></p>
                        )}
                      </div>
                    </React.Fragment>
                    )}
                  </QRCode>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </Content>
      <Footer />
    </React.Fragment>
  );
}

export default App;
