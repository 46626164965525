import React from 'react';

import styles from './Content.module.css';

interface Props {
  children: React.ReactNode
}

export default function Content(props: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  )
}

export function ContentTop(props: {children: React.ReactNode}) {
  return (
    <div className={styles.top}>
      {props.children}
    </div>
  )
}