import React from 'react';

import styles from './Header.module.css';
import logo from '../../assets/logo-black@2x.png';
import Tag from '../Tag';

export default function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.top}>
        <div className={styles.content}>
          <ul>
            <li>
              <a href="#">
                Your order (2)

                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.833008 0.833374H4.16634L6.39967 11.9917C6.47588 12.3754 6.6846 12.72 6.9893 12.9653C7.29399 13.2106 7.67526 13.3409 8.06634 13.3334H16.1663C16.5574 13.3409 16.9387 13.2106 17.2434 12.9653C17.5481 12.72 17.7568 12.3754 17.833 11.9917L19.1663 5.00004H4.99967M8.33301 17.5C8.33301 17.9603 7.95991 18.3334 7.49967 18.3334C7.03944 18.3334 6.66634 17.9603 6.66634 17.5C6.66634 17.0398 7.03944 16.6667 7.49967 16.6667C7.95991 16.6667 8.33301 17.0398 8.33301 17.5ZM17.4997 17.5C17.4997 17.9603 17.1266 18.3334 16.6663 18.3334C16.2061 18.3334 15.833 17.9603 15.833 17.5C15.833 17.0398 16.2061 16.6667 16.6663 16.6667C17.1266 16.6667 17.4997 17.0398 17.4997 17.5Z" stroke="#D0D3EB" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </li>
            <li>
              <a href="#">
                Log in
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.6663 16.5V14.8333C14.6663 13.9493 14.3152 13.1014 13.69 12.4763C13.0649 11.8512 12.2171 11.5 11.333 11.5H4.66634C3.78229 11.5 2.93444 11.8512 2.30932 12.4763C1.6842 13.1014 1.33301 13.9493 1.33301 14.8333V16.5M11.333 4.83333C11.333 6.67428 9.84062 8.16667 7.99967 8.16667C6.15873 8.16667 4.66634 6.67428 4.66634 4.83333C4.66634 2.99238 6.15873 1.5 7.99967 1.5C9.84062 1.5 11.333 2.99238 11.333 4.83333Z" stroke="#D0D3EB" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.navigation}>
        <div className={styles.left}>
          <a href="https://criipto.com" className={styles.logo}>
            <img src={logo} />
          </a>
          <Tag>Insurance - Demo</Tag>
        </div>
        <div className={styles.right}>
          <ul className={styles.links}>
            <li><a href="#">Products</a></li>
            <li><a href="#">FAQ</a></li>
            <li><a href="#">Claims</a></li>
          </ul>
          <a href="#" className={styles.cta}>Get an offer</a>
        </div>
      </div>
    </header>
  )
}