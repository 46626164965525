import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { CriiptoVerifyProvider } from '@criipto/verify-react';

import "@fontsource/ibm-plex-sans/400.css"; 
import "@fontsource/ibm-plex-sans/500.css"; 
import "@fontsource/ibm-plex-sans/700.css";

const searchParams = new URLSearchParams(window.location.search)
const domain = searchParams.get('domain') ?? sessionStorage.getItem('doamin') ?? process.env.REACT_APP_CRIIPTO_DOMAIN!;
const clientID = searchParams.get('client_id') ?? sessionStorage.getItem('client_id') ?? process.env.REACT_APP_CRIIPTO_CLIENT_ID!;

sessionStorage.setItem('domain', domain);
sessionStorage.setItem('client_id', clientID);

let root = document.getElementById('qr_demo_root');
if (!root) {
  root = document.createElement('div');
  root.id = "qr_demo_root";
  document.body.appendChild(root);
}

ReactDOM.render(
  <React.StrictMode>
    <CriiptoVerifyProvider
      domain={domain}
      clientID={clientID}
      response="token"
      redirectUri={window.location.origin + '/'}
    >
      <App />
    </CriiptoVerifyProvider>
  </React.StrictMode>,
  root
);
