import React from 'react';
import styles from './Tag.module.css';

interface Props {
  children: string
}

export default function Tag(props: Props) {
  return (
    <div className={styles.tag}>
      {props.children}
    </div>
  )
}