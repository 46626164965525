import React from 'react';

import styles from './Footer.module.css';
import logo from '../../assets/logo@2x.png';
import Tag from '../Tag';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.left}>
          <a href="https://criipto.com" className={styles.logo}>
            <img src={logo} />
          </a>
          <Tag>Insurance - Demo</Tag>
          <p>We ensure that the digital and physical world is a safe place to roam</p>
        </div>
        <div className={styles.right}>
          <div>
            <ul className={styles.links}>
              <li><a href="#">Legal</a></li>
              <li><a href="#">Privacy policy</a></li>
              <li><a href="#">Support</a></li>
              <li><a href="#">Documentation</a></li>
            </ul>
          </div>
          <div>
            <p>
              Criipto ApS<br />
              Dronninggårds Allé 136<br />
              2840 Holte
              <br />
              Denmark
            </p>
            <ul className={styles.links}>
              <li><a href="#">hello@criipto.com</a></li>
              <li><a href="#">sales@criipto.com</a></li>
              <li><a href="#">support@criipto.com</a></li>
            </ul>
            <ul className={styles.links}>
              <li><a href="#">Twitter</a></li>
              <li><a href="#">LinkedIn</a></li>
            </ul>
          </div>
        </div>
      </div>
      <p className={styles.footnote}>
        © 2022 Criipto ApS. All rights reserved.
      </p>
    </footer>
  )
}